<template>
    <div>
        <select-shop id="manageSalesHoursSelectShop" class="select-shop-container" :loadingButton="loadingButton"
            v-on:getData="getOrderShopSetting" />
        <CCard >
            <CCardBody >
                <CRow class="mt-3">
                    <CCol md="12" class="text-center">
                    <h2 class="font-weight-medium text-dark">
                    {{ $t('deliveryShopSetting') }}
                    </h2>
                    <p>{{ $t('decShopDelivery') }}</p>
                </CCol>
                </CRow>
                <hr />
                <div v-if="isGrabOrderEnabled">
                <CCard border-color="light">
                    <CCardBody >
                        
                        <table style="width:100%">
                            <tr class="font-weight-medium h5">
                                <td style="width:auto;" class="text-left d-flex">
                                <CIcon name="cil-settings" size="2xl" class="text-success" />
                                <p class="my-auto ml-2" >
                                    {{ $t('shopOnOff') }}
                                </p>
                                </td>

                                <td style="width:70%" class="text-right">
                                    <div class="row mb-2">
                                        <CButton 
                                            color="success" class="col-3" 
                                            style="font-size: smaller; border-radius: unset;" 
                                            :class="{ active: duration === '' }" 
                                            variant="outline"  
                                            @click="showIsOpenOrder('')"
                                            :disabled="loadingDuration === ''">
                                            <span v-if="loadingDuration === ''">
                                                <span class="spinner-border spinner-border-sm"></span>
                                            </span>
                                            <span v-else>{{ $t("open") }}</span>
                                        </CButton>  

                                        <CButton 
                                            color="success" class="col-3" 
                                            style="font-size: smaller; border-radius: unset;" 
                                            :class="{ active: duration === '30m' }" 
                                            variant="outline"  
                                            @click="showIsOpenOrder('30m')"
                                            :disabled="loadingDuration === '30m'">
                                            <span v-if="loadingDuration === '30m'">
                                                <span class="spinner-border spinner-border-sm"></span>
                                            </span>
                                            <span v-else>{{ $t("duration30M") }}</span>
                                        </CButton>                                       

                                        <CButton 
                                            color="success" class="col-3"  
                                            style="font-size: smaller; border-radius: unset;" 
                                            :class="{ active: duration === '1h' }" 
                                            variant="outline"  
                                            @click="showIsOpenOrder('1h')"
                                            :disabled="loadingDuration === '1h'">
                                            <span v-if="loadingDuration === '1h'">
                                                <span class="spinner-border spinner-border-sm"></span>
                                            </span>
                                            <span v-else>{{ $t("duration1H") }}</span>
                                        </CButton>

                                        <CButton 
                                            color="success" class="col-3"  
                                            style="font-size: smaller; border-radius: unset;" 
                                            :class="{ active: duration === '24h' }" 
                                            variant="outline"  
                                            @click="showIsOpenOrder('24h')"
                                            :disabled="loadingDuration === '24h'">
                                            <span v-if="loadingDuration === '24h'">
                                                <span class="spinner-border spinner-border-sm"></span>
                                            </span>
                                            <span v-else>{{ $t("duration24H") }}</span>
                                        </CButton>
                                    </div>
                                </td>

                            </tr>
                        </table>
                    </CCardBody>
                </CCard>
                <CCard border-color="light">
                    <CCardBody >
                        <h5></h5>
                        <table style="width:100%">
                            <tr class="font-weight-medium h5">
                                <td style="width:auto;" class="text-left d-flex ">

                                    <CIcon name="cil-basket" size="2xl" class="text-success " />
                                    <div  class="ml-2">
                                        <p class="my-auto ">
                                        {{ $t('openOrderTxt') }}
                                        </p>
                                        <small class="my-auto">{{ $t('openOrderDes') }}</small>
                                    </div>
                                </td>
                                <td v-if="isOpenOrder === true" class="text-right text-success" >
                                    {{ $t('openOrder') }}
                                </td>
                                <td  v-if="isOpenOrder === false" class="text-right text-danger" >
                                    {{ $t('closeOrder') }}
                                </td>
                                <td style="width:5%" class="text-right" >
                                    <CSwitch
                                        v-model="isOpenOrder"
                                        color="success"    
                                        :checked.sync="isOpenOrder"
                                        @update:checked="updateCreateOrderShopSetting"
                                        :disabled="loadingSwitch"
                                    />
                                    <span v-if="loadingSwitch" class="spinner-border spinner-border-sm text-success ml-2"></span>
                                </td>
                            </tr>
                        </table>
                    </CCardBody>
                </CCard>
                 <CCard border-color="light">
                    <CCardBody>
                        <table style="width:100%">
                            <tr class="font-weight-medium h5">
                                <td style="width:auto;" class="text-left d-flex">
                                <CIcon name="cil-clock" size="2xl" class="text-success" />
                                <p class="my-auto ml-2" >
                                    {{ $t('shopopen') }}
                                </p>
                                </td>
                                <td style="width:20%" class="text-right">
                                        <CButton color="primary" block  style="font-size: smaller;border-radius: unset;"  variant="outline"  v-if="!loadingSaveButton"  @click="updateStoreDeliveryHours()">
                                            {{ $t("save") }}
                                        </CButton>  
                                        <CButton block color="primary" disabled v-else>
                                            <CSpinner color="white" size="sm" />{{ $t('save') }}
                                        </CButton>
                                </td>
                            </tr>
                        </table>
                        <CAlert class="mt-3" color="danger" v-if="saveErrorMsg != ''" :show="Number(3)">{{ saveErrorMsg }}</CAlert>
                        <div class="mt-3">
                            <div v-for="([dayKey, day], dayIndex) in Object.entries(openingHour)"
                                :key="dayIndex"
                                :id="'editSalesHours-daySchedule-' + dayKey"
                                class="day-schedule">
                                <CRow id="editSalesHours-dayRow" class="justify-content-between m-1">
                                    <CCol id="editSalesHours-dayCheckboxColumn" col="12" sm="2" md="2" lg="3">
                                        <div id="editSalesHours-dayHeader" class="day-header mt-2 text-dark">
                                            <input id="editSalesHours-dayCheckbox"
                                                type="checkbox"
                                                class="mr-5 ml-3"
                                                v-model="day.enable"  />
                                            <span class="text-dark font-weight-normal"> {{ $t(hoursName(dayKey)) }}</span>
                                        </div>
                                    </CCol>
                                    <CCol id="editSalesHours-timeSlotColumn" col="12" md="8" lg="9" class="text-right">
                                        <CRow v-for="(slot, slotIndex) in day.slottime"
                                            :key="slotIndex"
                                            class="justify-content-end m-2">
                                            <CCol id="editSalesHours-startTimeColumn" col sm="4" md="4" lg="3" class="text-right">
                                                <vue-timepicker
                                                    id="editSalesHours-startTimePicker"
                                                    input-class="form-control"
                                                    v-model="slot.startTime"
                                                    :disabled="!day.enable"
                                                   />
                                            </CCol>
                                            <CCol id="editSalesHours-separatorColumn" col="1" md="1" lg="1" class="text-center">
                                                <span>-</span>
                                            </CCol>
                                            <CCol id="editSalesHours-endTimeColumn" col="5" md="4" lg="3" class="text-left">
                                                <vue-timepicker
                                                    id="editSalesHours-endTimePicker"
                                                    input-class="form-control"
                                                    v-model="slot.endTime"
                                                    :disabled="!day.enable"
                                                    />
                                            </CCol>
                                            <CCol id="editSalesHours-actionsColumn" col="1" md="1" lg="1" class="text-right">
                                                <button id="editSalesHours-addSlotButton"
                                                        class="add-btn"
                                                        @click="addSlot(dayKey)"
                                                        :disabled="!day.enable || day.slottime.length >= maxSlots"
                                                        v-if="slotIndex === 0">
                                                    <img style="width: 25px;" src="/img/flat-color-icons--plus.png" />
                                                </button>
                                                <button v-else
                                                        id="editSalesHours-removeSlotButton"
                                                        class="delete-btn"
                                                        @click="removeSlot(dayKey, slotIndex)">
                                                    <img src="/img/product/delete32.png" style="width: 30px;" />
                                                </button>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </div>
                        </div>
                    </CCardBody>
                 </CCard>
                </div>
                <div v-else>
                    <access-grab></access-grab>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>
<script>
import pos from '@/services/local';
import { mapGetters } from 'vuex'
import util from '@/util/util'
import VueTimepicker from "vue2-timepicker"
export default {
    components: {
        VueTimepicker,
    },
    data() {
        return {
            orderShopSetting : {},
            isOpenOrder :  true,
            duration : '',
            openingHour : {},
            loadingButton: true,
            maxSlots :  3,
            saveErrorMsg : '',
            loadingSaveButton: false,
            loadingDuration: null, // เก็บค่าปุ่มที่กำลังกด
            loadingSwitch: false, // ใช้สำหรับแสดง Loading
            isGrabOrderEnabled : false,

        }
    },
    created() {
        this.getOrderShopSetting()
        this.getStoreHours()
    },
    computed: {
        ...mapGetters(['shops', 'date', 'users']),
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },

    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getOrderShopSetting()
                this.getStoreHours()
            }
        },
        // duration: {
        //     handler() {
        //         console.log(this.duration); 
        //     }, 
        //     deep: true
        // }
        // openingHour: {
        //     handler() {
        //         this.updateStoreDeliveryHours();
        //     },
        //     deep: true // Ensures nested changes inside openingHour trigger the watch function
        // }
    },
    methods : {
        hoursName(index) {
            let data = util.getDayName(index);
            return data
        },
        async showIsOpenOrder(status) {
            this.loadingDuration = status; // กำหนดปุ่มที่กำลังกดให้โหลด
            this.duration = status;

            try {
                await this.pauseStore(); // เรียกใช้งาน pauseStore()
            } catch (error) {
                console.error("Error pausing store:", error);
            } finally {
                this.loadingDuration = null; // ปิด loading เมื่อเสร็จสิ้น
            }
        },
        removeSlot(day, slotIndex) {
            if (!this.openingHour || !this.openingHour[day]) {
                return;
            }
            const serviceHours = this.openingHour[day];

            if (!serviceHours.slottime || serviceHours.slottime.length === 0) {
                return;
            }

            if (slotIndex < 0 || slotIndex >= serviceHours.slottime.length) {
                return;
            }
            // Remove the selected slot
            serviceHours.slottime.splice(slotIndex, 1);

        },
        addSlot(item) {
            if (!this.openingHour || !this.openingHour[item]) {
                return;
            }

            const maxSlots = this.maxSlots || 3; // Ensure maxSlots is defined
            const serviceHours = this.openingHour[item];

            if (serviceHours.slottime.length >= maxSlots) {
                alert(this.$t("maxSlotsReached"));
                return;
            }

            let newStartTime = "00:00";
            if (serviceHours.slottime.length > 0) {
                const lastSlotEndTime = serviceHours.slottime[serviceHours.slottime.length - 1].endTime;
                let [lastHour, lastMinute] = lastSlotEndTime.split(":").map(Number);
                let newStartMinute = lastMinute + 1;
                let newStartHour = lastHour;

                if (newStartMinute >= 60) {
                    newStartHour += Math.floor(newStartMinute / 60);
                    newStartMinute %= 60;
                }

                newStartTime = `${String(newStartHour).padStart(2, "0")}:${String(newStartMinute).padStart(2, "0")}`;
            }

            const maxTime = "23:59";
            if (newStartTime >= maxTime) {
                alert(this.$t("noMoreTimeSlots"));
                return;
            }

            const newSlot = {
                startTime: newStartTime,
                endTime: maxTime
            };

            const isOverlapping = serviceHours.slottime.some((slot) => (
                (newSlot.startTime >= slot.startTime && newSlot.startTime < slot.endTime) ||
                (newSlot.endTime > slot.startTime && newSlot.endTime <= slot.endTime) ||
                (newSlot.startTime <= slot.startTime && newSlot.endTime >= slot.endTime)
            ));

            if (isOverlapping) {
                alert(this.$t("timeSlotOverlap"));
                return;
            }

            serviceHours.slottime.push(newSlot);
        },
        getOrderShopSetting() {
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v3.0/shop/ordershopsetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                console.log(res);
                
                if(res.status ==200){
                    let isGrabOrderEnabled  = res.data.data.isGrabOrderEnabled
                    if(isGrabOrderEnabled!== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled!==''){
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                    }else{
                        this.isGrabOrderEnabled = false
                    }
                }else{
                    this.isGrabOrderEnabled = false
                }
                
                if(res.data.data.grab.isPause){
                    this.duration = res.data.data.grab.pauseDuration 
                }else{
                    this.duration = ''
                }

                if(res.data.data.grab.isOpenOrder !== undefined && res.data.data.grab.isOpenOrder !== null){
                    this.isOpenOrder = res.data.data.grab.isOpenOrder
                }else{
                    this.isOpenOrder = true
                }                
                this.orderShopSetting = res?.data?.data[0] || null
            })

        },
        getStoreHours(){
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v3.0/grab/store/hours',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                let openingHour = res?.data?.data.openingHour || null
                this.openingHour = Object.entries(openingHour).reduce((acc, [day, slots]) => {
                    acc[day] = {
                        enable: slots.length > 0,
                        slottime: slots.length > 0 ? slots : [{ startTime: "09:30", endTime: "21:30" }]
                    };
                    return acc;
                }, {});             
            })
        },
        async  updateStoreDeliveryHours(){
            this.isSuccess = false
            this.saveErrorMsg = ''
            this.loadingSaveButton = true
            this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)

            const shopObjectId = this.shopObjectId
            const headers = { shopObjectId: shopObjectId }
            // Prepare the serviceHours object by removing "enable" and setting the appropriate "openPeriodType"
            const serviceHours = {};

            Object.keys(this.openingHour).forEach((key) => {
                if (!this.openingHour[key].enable) {
                    serviceHours[key] = [];
                } else {
                    serviceHours[key] = this.openingHour[key].slottime;
                }
            });

            let data = {
                shopObjectId: shopObjectId,
                openingHour : serviceHours
            }
            try {
                const response = await pos({
                    method: 'PATCH',
                    url: '/api/v3.0/grab/store/hours/open',
                    data: data,
                    headers: headers,
                })
                this.isSuccess = true
                if(response.data.error.code != 0){
                    this.saveErrorMsg = response.data.error.message
                }

                this.loadingSaveButton = false
            } catch (error) {
                this.loadingSaveButton = false
            }

        },
        async pauseStore(){
            const shopObjectId = this.shopObjectId
            const headers = { shopObjectId: shopObjectId }

            let params = {
                shopObjectId: this.shopObjectId,
            }
            let data = {}
            let isPause = true

            if(this.duration == ''){
                data = {
                    shopObjectId: shopObjectId,
                    isPause :  false
                }
            }else{
                data = {
                    shopObjectId: shopObjectId,
                    isPause : isPause,
                    duration : this.duration
                }
            }
            
            try {
                await pos({
                    method: 'PATCH',
                    url: '/api/v3.0/grab/store/pause',
                    data: data,
                    params : params,
                    headers: headers,
                })
                this.isSuccess = true
                this.addSalesHoursModal = false
                this.sellingname = ''
                this.loadingSaveButton = false
            } catch (error) {
                this.loadingSaveButton = false
            }
            
        },
        updateCreateOrderShopSetting(){
            const shopObjectId = this.shopObjectId
            const headers = { shopObjectId: shopObjectId }

            let params = {
                shopObjectId: this.shopObjectId,
            }
            
            let data = {
                grab: {
                    isOpenOrder: this.isOpenOrder
                }
            }
            this.loadingSwitch = true; // เปิด Loading
            try {
                pos({
                    method: 'POST',
                    url: '/api/v3.0/shop/ordershopsetting',
                    data: data,
                    params : params,
                    headers: headers,
                })
                this.loadingSaveButton = false
            } catch (error) {
                this.loadingSaveButton = false
            }finally {
                this.loadingSwitch = false; // ปิด Loading เมื่อ API เสร็จ
            }

        }

    }
}
</script>
<style scoped>
.schedule-container {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.day-schedule {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    padding: 1px;
    border: 0.9px solid;
    border-color: #E5E5E5;
    border-radius: 5px;
}

.day-schedule:hover {
    background: #f0f8ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-color: #d1d1d1;
}

.day-header {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
}

.add-btn {
    background: none;
    border: none;
    color: green;
    cursor: pointer;
}

.delete-btn {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
}

.vue-content-placeholders-heading__title {
    position: relative;
    overflow: hidden;
    height: 25px;
    width: 85%;
    margin-bottom: 10px;
}

.vue-content-placeholders-heading__subtitle {
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 25px;
    width: 90%;
}

.vue-content-placeholders-text__line {
    position: relative;
    overflow: hidden;
    height: 20px;
    width: 100%;
    margin-bottom: 10px;
}

/* word-wrap: break-word; */
</style>

